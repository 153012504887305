import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";

const ContentBody = loadable(() => import("@organisms/ContentBody"));

const CareerSinglePage = ({ data, pageContext }) => {
  const { type, hero, blocks, meta } = data;

  const [, dispatch] = useAppState();
  useEffect(() => {
    dispatch({ type: "setThemeLight" });
  }, []);

  return (
    <PageContainer meta={meta}>
      <ContentBody
        type={type}
        hero={hero}
        blocks={blocks}
        url={meta.url}
        back={{ url: "/about/careers", text: "All Careers" }}
      />
    </PageContainer>
  );
};

CareerSinglePage.defaultProps = {};

export default CareerSinglePage;
