/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
import PageTemplate from "@pageTemplates/CareerSinglePage";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query CareerSingleQuery($uri: [String]) {
    craft {
      entry(uri: $uri) {
        type: typeHandle
        title
        uid
        url
        slug
        ... on Craft_careers_default_Entry {
          descriptor: descriptor0
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          contentBuilder {
            ...ContentBuilderFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    type,
    descriptor,
    contentBuilder,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  return {
    type,
    hero: {
      heading: title,
      descriptor,
    },
    blocks: resolveContentBuilder(contentBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CareersQuery"
    {...props}
  />
);

export default Page;
